<template>
  <div class="section">
    <back-button label="Dimensiones" to="/dimensions" />

    <div class="box">
      <h2>Medición eNPS</h2>
    </div>

    <div class="box">
      <h3>eNPS (Employee Net Promoter Score)</h3>
    </div>

    <div class="flex">
      <div class="col">
        <div class="box">
          <p>
            Esta herramienta permite identificar la probabilidad de que un
            colaborador recomiende la organización como lugar de trabajo. Así
            podrás medir la experiencia del colaborador dentro de la
            organización siendo embajador de la marca.
          </p>
          <p>
            Esta metodología tiene un impacto importante en la rotación y la
            retención de personal, y en la valoración de la idoneidad de asumir
            o no un proyecto concreto.
          </p>
          <p>
            Al calcular el <strong>Employee Net Promoter Score</strong>,
            podremos descubrir las oportunidades de mejora de la organización,
            constatar la confianza en los líderes, y favorecer un clima de
            confianza y retroalimentación.
          </p>
          <p>
            La medición <strong>eNPS</strong> puede ser el primer paso para ver
            un cambio radical en el funcionamiento de tu empresa.
          </p>
        </div>

        <div class="box">
          <h3>Pregunta eNPS</h3>
          <p>
            En una escala del 0 al 10, ¿cuánto recomendarías a un amigo trabajar
            en esta empresa?
          </p>
        </div>
      </div>

      <div class="col">
        <img src="../../assets/enps.jpg" alt="enps" />
      </div>
    </div>

    <logo />
  </div>
</template>

<script>
import BackButton from "../../components/BackButton.vue";
import Logo from "../../components/Logo.vue";

export default {
  name: "EngagementModal",

  components: { Logo, BackButton }
};
</script>

<style scoped>
.section {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
}

.box {
  margin: var(--lengthSm1);
  padding: var(--lengthMd1);
  background-color: var(--grayColor1);
  border-radius: 10px;
}

.logo-container {
  position: absolute;
  bottom: 20px;
  right: 10px;
}

h3 {
  color: var(--mainColor1);
}

h3 + span {
  margin-top: var(--lengthSm1);
}

h2,
h3:last-child,
h4,
p:not(h3 + p) {
  text-align: center;
}

p {
  font-size: var(--lengthMd1);
  color: var(--fontColor1);
}

p + p {
  margin-top: var(--lengthMd2);
}

.col:first-child {
  flex-basis: 58%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: var(--lengthMd1);
  gap: var(--lengthMd1);
}

.col:last-child {
  flex-basis: 40%;
}

.flex,
.col .box:first-child {
  flex-grow: 1;
  justify-content: space-between;
}

.col img {
  width: 70%;
  margin: auto;
}
</style>
